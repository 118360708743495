import { z } from "zod";
import ValidatableForm, { useForm } from "../common/ValidatableForm";
import ValidatableFormField from "../common/ValidatableFormField";
import EventHeader from "../common/EventHeader";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../context/SessionContext";
import auth from "../../services/authService";
import { toast } from "react-toastify";

const LoginPage = () => {
  const [session, setSession] = useSessionContext();
  const navigate = useNavigate();

  const form = useForm({
    schema: formSchema,
  });

  const handleLogin = async (loginData: z.infer<typeof formSchema>) => {
    const status = await auth.login(loginData);

    if (status === 200) {
      toast.success("Logged in");
      setSession({ ...session, user: auth.getUser(), redirectPath: "" });
      navigate(session.redirectPath ? session.redirectPath : "/");
    } else {
      if (status === 401 || status === 404)
        form.setError("password", { message: "Invalid email or password" });
      else {
        form.reset();
        toast.error("Unexpected Error");
      }
    }
  };

  const handleLogout = () => {
    setSession({ ...session, user: null, redirectPath: "" });
    auth.logout();
    navigate(session.redirectPath ? session.redirectPath : "/");
  };

  return (
    <div>
      <EventHeader />

      {!session.user && (
        <>
          <h1 className="mb-4 text-[36px] font-light">Login</h1>

          <ValidatableForm form={form} onSubmit={handleLogin}>
            <ValidatableFormField
              label="E-Mail"
              type="email"
              placeholder=""
              additionalClasses="max-w-[300px]"
              {...form.register("email")}
            />

            <ValidatableFormField
              label="Passwort"
              type="password"
              placeholder=""
              additionalClasses="max-w-[300px]"
              {...form.register("password")}
            />

            <ValidatableFormField
              label="Login"
              type="submit"
              additionalClasses="mr-auto"
            />
          </ValidatableForm>
        </>
      )}
      {session.user && (
        <>
          <h1 className="mb-4 text-[36px] font-light">
            {`Hello ${session.user.firstname}!`}
          </h1>
          <h2 className="mb-2 text-[22px]">Account Info</h2>
          <div className="mb-4 flex flex-col">
            <p>{"Firstname: " + session.user.firstname}</p>
            <p>{"Lastname: " + session.user.lastname}</p>
            <p>{"E-Mail: " + session.user.email}</p>
          </div>

          <input
            type="button"
            value="Logout"
            className="btn-primary"
            onClick={handleLogout}
          />
        </>
      )}
    </div>
  );
};

const formSchema = z.object({
  email: z.string().email("Please enter a valid email address."),
  password: z.string().min(1, "Please enter your password"),
});

export default LoginPage;
