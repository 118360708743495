import jwtDecode from "jwt-decode";
import {
  AuthenticationResponse,
  AuthenticationToken,
  TOKEN_EXPIRED,
  UserLoginAttempt,
  UserWithoutPassword,
} from "../user_temporary";
import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/users";
const accessTokenKey = "authAccessToken";
const refreshTokenKey = "authRefreshToken";

init();

function init() {
  const accessToken = getAccessToken();
  if (accessToken) http.setAuthAccessToken(accessToken);

  http.setAuthRefreshTokenFunc(refresh);
  http.setTokenExpiredMessage(TOKEN_EXPIRED);
}

function getAccessToken() {
  return localStorage.getItem(accessTokenKey);
}

function setAccessToken(accessToken: string) {
  localStorage.setItem(accessTokenKey, accessToken);
  http.setAuthAccessToken(accessToken);
}

function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey);
}

function setRefreshToken(refreshToken: string) {
  localStorage.setItem(refreshTokenKey, refreshToken);
}

async function login(loginAttempt: UserLoginAttempt) {
  try {
    const response = await http.post<AuthenticationResponse>(
      apiEndpoint + "/login",
      loginAttempt
    );

    setAccessToken(response.data.accessToken.token);
    setRefreshToken(response.data.refreshToken.token);

    return response.status;
  } catch (error: any) {
    return http.handleError(error);
  }
}

function logout() {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
}

async function refresh() {
  try {
    const token = getRefreshToken();

    if (token) {
      const response = await http.post<AuthenticationToken>(
        apiEndpoint + "/refresh",
        { token }
      );

      setAccessToken(response.data.token);
      return response.status;
    } else throw Error("refresh token not available");
  } catch (error: any) {
    return http.handleError(error);
  }
}

function getUser() {
  try {
    const token = getAccessToken();
    if (!token) return null;
    return jwtDecode<UserWithoutPassword>(token);
  } catch (error) {
    return null;
  }
}

const exportObject = {
  login,
  logout,
  refresh,
  getUser,
};

export default exportObject;
