import { useState, useEffect } from "react";
import { Event } from "@code-on-the-rocks/ticket-flamingo-common/dist/Event";
import EventHeader from "../common/EventHeader";
import EventListGroup from "../event/EventListGroup";
import { getEvents, removeEvent, saveEvent } from "../../api/eventApi";
import { toast } from "react-toastify";
import _ from "lodash";
import { EventWithoutIdImplementation } from "@code-on-the-rocks/ticket-flamingo-common";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../context/SessionContext";
import auth from "../../services/authService";

const EventListPage = () => {
  const [events, handleDeleteEvent, handleDuplicateEvent] =
    useEventListPageLogic();

  return (
    <div>
      <EventHeader showNavigateBack={false} />
      {events.length > 0 ? (
        <EventListGroup
          events={events}
          onDeleteEvent={handleDeleteEvent}
          onDuplicateEvent={handleDuplicateEvent}
        />
      ) : (
        <div className="m-5 text-lg">Sorry, there is no event right now.</div>
      )}
    </div>
  );
};

function useEventListPageLogic(): [
  Event[],
  (id: string) => void,
  (id: string) => void
] {
  const [events, setEvents] = useState<Event[]>([]);
  const navigate = useNavigate();
  const [session, setSession] = useSessionContext();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getEvents();
      const responseEvents = response;
      setEvents(responseEvents);
    };

    fetchData();
  }, []);

  const handleDeleteEvent = async (id: string) => {
    const originalEvents = _.clone(events);
    const newEvents = events.filter((event) => event.id !== id);
    setEvents(newEvents);

    try {
      await removeEvent(id);
      toast.success("Event removed");
    } catch (error: any) {
      if (error.response && error.response.status === 404)
        toast.error("Event has already been deleted");
      else if (error.response && error.response.status === 401) {
        toast.error("Unauthorized");
        clearSession();
      } else {
        toast.error("Something went wrong.");
      }

      setEvents(originalEvents);
    }
  };

  // TODO: do not copy sold-slots
  const handleDuplicateEvent = async (id: string) => {
    const eventToDuplicate = events.find((event) => event.id === id);

    if (eventToDuplicate) {
      const originalEvents = _.clone(events);
      const newEvent = new EventWithoutIdImplementation(
        eventToDuplicate.title,
        eventToDuplicate.descriptionShort,
        eventToDuplicate.descriptionLong,
        eventToDuplicate.location,
        eventToDuplicate.start,
        eventToDuplicate.end,
        eventToDuplicate.slotsAvailable,
        eventToDuplicate.image,
        eventToDuplicate.options // TODO: remove ID ????
      );

      try {
        const duplicateEventResponse = await saveEvent(newEvent);
        const newEvents = [duplicateEventResponse, ...originalEvents];

        setEvents(newEvents);
        toast.success("Event duplicated");
      } catch (error: any) {
        if (error.response && error.response.status === 400)
          toast.error("Invalid data has been passed to server.");
        else if (error.response && error.response.status === 401) {
          toast.error("Unauthorized");
          clearSession();
        } else toast.error("Something went wrong.");

        setEvents(originalEvents);
      }
    }
  };

  function clearSession() {
    setSession({ ...session, user: null, redirectPath: "" });
    auth.logout();
    navigate("/login");
  }

  return [events, handleDeleteEvent, handleDuplicateEvent];
}

export default EventListPage;
