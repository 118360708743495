import EventHeader from "../common/EventHeader";
import EventEditForm from "../event/EventEditForm";

const EventEditPage = () => {
  return (
    <div>
      <EventHeader />
      <h1 className="text-[36px] font-light">Neue Veranstaltung</h1>
      <EventEditForm />
    </div>
  );
};

export default EventEditPage;
