export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

export function toUser(user: UserWithoutId, id: string): User {
  return { id: id, ...user };
}

export function toUserWithoutPassword(user: User): UserWithoutPassword {
  return {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
}

export interface UserWithoutId {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export interface User extends UserWithoutId {
  id: string;
}

export interface UserLoginAttempt {
  email: string;
  password: string;
}

export interface UserWithoutPassword {
  firstname: string;
  lastname: string;
  email: string;
}

export class UserWithoutIdImplementation implements UserWithoutId {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  constructor(
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.password = password;
  }
}

export class UserImplementation
  extends UserWithoutIdImplementation
  implements User
{
  id: string;
  constructor(
    id: string,
    firstname: string,
    lastname: string,
    email: string,
    password: string
  ) {
    super(firstname, lastname, email, password);
    this.id = id;
  }
}

export class UserLoginAttemptImplementation implements UserLoginAttempt {
  email: string;
  password: string;
  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class UserWithoutPasswordImplementation implements UserWithoutPassword {
  firstname: string;
  lastname: string;
  email: string;
  constructor(firstname: string, lastname: string, email: string) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
  }
  getPlainData() {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
    };
  }
}

// AUTH Section
// TODO: this should be a seperete file

export interface AuthenticationToken {
  token: string;
}

export interface AuthenticationResponse {
  accessToken: AuthenticationToken;
  refreshToken: AuthenticationToken;
}
