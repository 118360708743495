import { EventOption } from "@code-on-the-rocks/ticket-flamingo-common/dist/EventOption";

export interface TicketBuyIntent extends EventOption {
  amount: number;
}

interface TicketProps {
  ticket: TicketBuyIntent;
  amountChangeable: boolean;
  onTicketAmountChange?: (ticketId: string, add: number) => void;
}

const TicketItem = (props: TicketProps) => {
  const { ticket, amountChangeable, onTicketAmountChange } = props;

  return (
    <tr
      key={ticket.id}
      className="table-row border-t-[1px] border-primaryDark text-[18px] font-medium"
    >
      <td className="table-cell py-3">{ticket.name}</td>
      <td className="table-cell py-3 text-center">{ticket.price} €</td>
      <td className="table-cell py-3 text-center">
        {amountChangeable && (
          <button
            className="rounded-[10px] border-[1px] border-primary px-1 text-primaryDark hover:border-primaryDark"
            onClick={() => {
              if (onTicketAmountChange) onTicketAmountChange(ticket.id, -1);
            }}
          >
            -
          </button>
        )}
        <span className="inline-block w-[30px] text-center">
          {ticket.amount}
        </span>
        {amountChangeable && (
          <button
            className="rounded-[10px] border-[1px] border-primary px-1 text-primaryDark hover:border-primaryDark"
            onClick={() => {
              if (onTicketAmountChange) onTicketAmountChange(ticket.id, 1);
            }}
          >
            +
          </button>
        )}
      </td>
    </tr>
  );
};

export default TicketItem;
