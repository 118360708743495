import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyEnvironment } from "./utils/helper-funcs";
import { SessionContextProvider } from "./components/context/SessionContext";

verifyEnvironment(["REACT_APP_API_URL"]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// TODO
const initialOptions = {
  "client-id":
    "AX8k3msPey8mp9QyxgQ6Y-JmTFUvECzzjUj4ZRkPpopW7PN01g5-79BejViu9nenyyLbN97kvq9SwEMX",
  currency: "EUR",
  intent: "capture",
  //"data-client-token": "abc123xyz==",
  "integration-date": "2023-03-09",
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer />
      <PayPalScriptProvider options={initialOptions}>
        <SessionContextProvider>
          <App />
        </SessionContextProvider>
      </PayPalScriptProvider>
    </BrowserRouter>
  </React.StrictMode>
);
