import { DateTime } from "@code-on-the-rocks/ts-datetime";
import http from "../services/httpService";
import {
  Event,
  EventImplementation,
  EventWithoutId,
} from "@code-on-the-rocks/ticket-flamingo-common";

const apiEndpoint = process.env.REACT_APP_API_URL + "/events";

export async function getEvents() {
  try {
    const response = await http.get<Event[]>(apiEndpoint);

    //TODO: @neuer was sagst du dazu?
    return response.data.map(
      (eventData) =>
        new EventImplementation(
          eventData.id,
          eventData.title,
          eventData.descriptionShort,
          eventData.descriptionLong,
          eventData.location,
          new DateTime(eventData.start.milliseconds),
          new DateTime(eventData.end.milliseconds),
          eventData.slotsAvailable,
          eventData.image,
          eventData.options
        )
    );
  } catch (error: any) {
    http.handleError(error);
    return [];
  }
}

export async function getEvent(id: string) {
  try {
    const response = await http.get<Event>(`${apiEndpoint}/${id}`);

    return new EventImplementation(
      response.data.id,
      response.data.title,
      response.data.descriptionShort,
      response.data.descriptionLong,
      response.data.location,
      new DateTime(response.data.start.milliseconds),
      new DateTime(response.data.end.milliseconds),
      response.data.slotsAvailable,
      response.data.image,
      response.data.options
    );
  } catch (error: any) {
    http.handleError(error);
    return null;
  }
}

export async function saveEvent(event: EventWithoutId) {
  const response = await http.post<Event>(apiEndpoint, event);
  return new EventImplementation(
    response.data.id,
    response.data.title,
    response.data.descriptionShort,
    response.data.descriptionLong,
    response.data.location,
    new DateTime(response.data.start.milliseconds),
    new DateTime(response.data.end.milliseconds),
    response.data.slotsAvailable,
    response.data.image,
    response.data.options
  );
}

export function updateEvent(id: string, event: EventWithoutId) {
  return http.put<Event>(`${apiEndpoint}/${id}`, event);
}

export function removeEvent(id: string) {
  return http.delete<Event>(`${apiEndpoint}/${id}`);
}
