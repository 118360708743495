import { TicketBuyIntent } from "./TicketItem";
import TicketList from "./TicketList";
import { PropsWithChildren } from "react";
import { TicketCreationIntent } from "./TicketItemCreationIntent";

interface TicketSectionProps {
  creationMode: boolean;
  creationTickets?: TicketCreationIntent[];
  headline: string;
  tickets: TicketBuyIntent[];
  slotsAvailable: number;
  amountChangeable: boolean;
  onTicketAmountChange?: (ticketId: string, step: number) => void;
  onTicketsChange?: (tickets: TicketCreationIntent[]) => void;
}

const TicketSection = (props: PropsWithChildren<TicketSectionProps>) => {
  const {
    creationMode,
    creationTickets,
    headline,
    tickets,
    slotsAvailable,
    amountChangeable,
    onTicketAmountChange,
    onTicketsChange,
  } = props;

  const slotsAvailBg = slotsAvailable <= 3 ? " text-red/70" : "";
  const slotsAvailText =
    slotsAvailable === 1
      ? `${slotsAvailable} Platz frei`
      : `${slotsAvailable} Plätze frei`;

  return (
    <div className="max-w-[726px] rounded-[10px] border-2 border-primaryDark px-10 py-5">
      <h2 className="text-[28px] font-light">{headline}</h2>
      {slotsAvailable !== -1 && (
        <span className={"ml-1 text-[15px] font-light" + slotsAvailBg}>
          {slotsAvailText}
        </span>
      )}
      <TicketList
        creationMode={creationMode}
        creationTickets={creationTickets}
        tickets={tickets}
        amountChangeable={amountChangeable}
        onTicketAmountChange={onTicketAmountChange}
        onTicketsChange={onTicketsChange}
      />
      {props.children}
    </div>
  );
};

export default TicketSection;
