import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faPenToSquare,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useSessionContext } from "../context/SessionContext";

interface EventListItemButtonProps {
  eventId: string;
  onDeleteEvent: (id: string) => void;
  onDuplicateEvent: (id: string) => void;
}

const EventListItemButtons = (props: EventListItemButtonProps) => {
  const [sessionContext] = useSessionContext();

  return (
    <div className="flex items-center gap-4 pr-5 text-primaryDark">
      {sessionContext.user && (
        <>
          <button
            onClick={() => props.onDeleteEvent(props.eventId)}
            className="group flex hover:text-white hover:opacity-100"
          >
            <FontAwesomeIcon icon={faX} size="lg" />
          </button>

          <button
            onClick={() => props.onDuplicateEvent(props.eventId)}
            className="group flex hover:text-white hover:opacity-100"
          >
            <FontAwesomeIcon icon={faClone} size="lg" />
          </button>

          <Link
            to={"/events/edit/" + props.eventId}
            className="group flex hover:text-white hover:opacity-100"
          >
            {!sessionContext.user && <span>mehr</span>}
            <FontAwesomeIcon icon={faPenToSquare} size="lg" />
          </Link>
        </>
      )}

      <Link
        to={"/events/" + props.eventId}
        className="group flex hover:text-white hover:opacity-100"
      >
        {!sessionContext.user && <span>mehr</span>}
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </Link>
    </div>
  );
};

export default EventListItemButtons;
