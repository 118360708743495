import { EventOption } from "@code-on-the-rocks/ticket-flamingo-common/dist/EventOption";
import ErrorLabel from "../common/ErrorLabel";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

interface TicketProps {
  ticket: TicketCreationIntent;
  onTicketChange: (ticket: TicketCreationIntent) => void;
  onTicketRemove: (ticketId: string) => void;
}

export interface TicketCreationIntent extends EventOption {
  // slotsAvailable: number;
}

const TicketItem = (props: TicketProps) => {
  const { ticket, onTicketChange, onTicketRemove } = props;
  const [showValidationError, setShowValidationError] = useState(false);

  const onTicketNameChange = (name: string) => {
    const newTicket = { ...ticket };
    newTicket.name = name;
    onTicketChange(newTicket);
  };

  const onTicketPriceChange = (price: string) => {
    const newTicket = { ...ticket };
    newTicket.price = Number(price);
    onTicketChange(newTicket);
  };

  return (
    <tr
      key={ticket.id}
      className="table-row border-t-[1px] border-primaryDark text-[16px] font-normal"
    >
      <td className="table-cell py-3 align-top">
        <div className="flex flex-col">
          <input
            autoFocus={ticket.name.trim() === ""}
            value={ticket.name}
            onChange={(e) => onTicketNameChange(e.target.value)}
            onBlur={(e) => setShowValidationError(e.target.value.trim() === "")}
            className="rounded-[10px] border-[1px] border-primaryDark px-4 py-1 focus:outline-primaryDark"
          />
          {showValidationError && (
            <ErrorLabel text="Bitte Ticketnamen eingeben" />
          )}
        </div>
      </td>
      <td className="table-cell py-3 text-center align-top">
        <div className="flex flex-row items-center gap-1">
          <input
            value={ticket.price}
            type="number"
            onChange={(e) => onTicketPriceChange(e.target.value)}
            className="max-w-[75px] rounded-[10px] border-[1px] border-primaryDark px-4 py-1 focus:outline-primaryDark"
          />
          <span className="text-xl">€</span>
        </div>
      </td>
      {/* <td className="table-cell py-3 text-center">
        <input
          value={ticket.slotsAvailable}
          onChange={(e) =>
            onTicketChange(ticket.id, "slotsAvailable", e.target.value)
          }
          className="max-w-[75px] rounded-[10px] border-[1px] border-primaryDark px-4 py-1 focus:outline-primaryDark"
        />
      </td> */}
      <td className="table-cell py-3 pl-3 pt-4 text-center align-top">
        <FontAwesomeIcon
          icon={faCircleXmark}
          size="lg"
          className="cursor-pointer text-red/80 hover:text-red"
          onClick={() => onTicketRemove(ticket.id)}
        />
      </td>
    </tr>
  );
};

export default TicketItem;
