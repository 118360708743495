import { createContext, ReactNode, useState } from "react";
import { CheckOutData } from "../pages/CheckOutPage";

type CheckOutContextType = {
  checkOutData: CheckOutData;
  setCheckOutData: React.Dispatch<React.SetStateAction<CheckOutData>>;
};

export const checkOutContext = createContext<CheckOutContextType | null>(null);
checkOutContext.displayName = "CheckOutContext";

const CheckOutContext = ({ children }: { children: ReactNode | undefined }) => {
  const [checkOutData, setcheckOutData] = useState<CheckOutData>({
    title: "",
    tickets: [],
    customerInfo: null,
  });

  return (
    <>
      <checkOutContext.Provider
        value={{ checkOutData, setCheckOutData: setcheckOutData }}
      >
        {children}
      </checkOutContext.Provider>
    </>
  );
};

export default CheckOutContext;
