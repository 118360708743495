import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const subMenuRef = useRef(null);
  useClickOutsideAlerter(subMenuRef, () => setShowSubMenu(false));

  const toggleSubMenu = () => {
    setShowSubMenu((current) => {
      return !current;
    });
  };

  return (
    <nav className="h-full">
      <div
        onClick={toggleSubMenu}
        className="flex h-full cursor-pointer items-center rounded-[10px] bg-primaryDark px-[20px] text-[26px] font-normal hover:text-white hover:opacity-80 "
      >
        +
      </div>
      {showSubMenu && (
        <div
          ref={subMenuRef}
          className={
            "absolute flex flex-col rounded-[10px] bg-primary px-4 py-2 text-[18px] shadow-lg "
          }
          onClick={toggleSubMenu}
        >
          <NavLink className={"hover:text-white"} to={"/events/new"}>
            New Event
          </NavLink>
          <NavLink
            className={"hover:text-white"}
            to={"/events/new-from-template"}
          >
            New From Template
          </NavLink>
          <NavLink className={"hover:text-white"} to={"/events/new-template"}>
            New Template
          </NavLink>
        </div>
      )}
    </nav>
  );

  function useClickOutsideAlerter(ref: any, callback: () => void) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
};

export default Navigation;
