import { useContext } from "react";
import { checkOutContext } from "../context/CheckOutContext";
import EventHeader from "../common/EventHeader";
import { TicketBuyIntent } from "../event-ticket/TicketItem";
import TicketSection from "../event-ticket/TicketSection";
import PayPalButtonWrapper, {
  OrderStatus,
  PayPalButtonWrapperItems,
} from "../common/PayPalButtonWrapper";
import { CustomerInfo } from "../event-ticket/CustomerInfoForm";

export interface CheckOutData {
  title: string;
  tickets: TicketBuyIntent[];
  customerInfo: CustomerInfo | null;
}

const CheckOutPage = () => {
  const [title, tickets, customerInfo] = useCheckoutLogic();

  const paypalPurchaseItems: PayPalButtonWrapperItems[] = tickets.map(
    (ticket) => {
      return {
        name: ticket.name,
        quantity: ticket.amount,
        price: ticket.price,
      };
    }
  );

  const renderTickets = !(
    title === "" ||
    tickets.length === 0 ||
    !customerInfo
  );

  return (
    <div>
      <EventHeader label="Veranstaltung" to={-1} />

      {renderTickets && (
        <TicketSection
          creationMode={false}
          headline={title}
          tickets={tickets}
          slotsAvailable={-1}
          amountChangeable={false}
        >
          <div className="flex">
            <div className="flex flex-1 flex-col">
              <div className="flex gap-1">
                <span>Name:</span>
                <span>{customerInfo.name}</span>
              </div>
              <div className="flex gap-1">
                <span>E-Mail:</span>
                <span>{customerInfo.email}</span>
              </div>
            </div>
            <div>
              <PayPalButtonWrapper
                currency_code="EUR"
                items={paypalPurchaseItems}
                onValidate={handleValidate}
                onCancel={handleCancel}
                onError={handleError}
                onApprove={handleApprove}
              />
            </div>
          </div>
        </TicketSection>
      )}

      {!renderTickets && (
        <div className="m-5 text-lg">
          Oops, there is nothing to checkout right now.
        </div>
      )}
    </div>
  );
};

function useCheckoutLogic(): [string, TicketBuyIntent[], CustomerInfo | null] {
  const { checkOutData } = useContext(checkOutContext)!;
  const { title, tickets, customerInfo } = checkOutData;

  return [title, tickets, customerInfo];
}

const handleValidate = (data: Record<string, unknown>) => {
  // transaction has been canceled
  // - show an cancel message
  // or return to cart
  return true;
};

const handleCancel = (data: Record<string, unknown>) => {
  // transaction has been canceled
  // - show an cancel message
  // or return to cart
};

const handleError = (error: Record<string, unknown>) => {
  // TODO: save errorDetails to Backend
  console.log(error);
  // Show error info: Bitte Support kontaktieren
};

const handleApprove = (orderId: string, orderStatus: OrderStatus) => {
  //call handle approve
  // Call backend function to fulfill order
  // if response is success
  // setPaidFor(true);
  // Refresh user's account or subscription status
  // if response is error
  // alert("Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at support@designcode.io for assistance.");
};

export default CheckOutPage;
