import { Event } from "@code-on-the-rocks/ticket-flamingo-common/dist/Event";
import EventListItemButtons from "./EventListItemButtons";

interface EventListItemProps {
  event: Event;
  onDeleteEvent: (id: string) => void;
  onDuplicateEvent: (id: string) => void;
}

const EventListItem = (props: EventListItemProps) => {
  const { event, onDeleteEvent, onDuplicateEvent } = props;

  const {
    slotsAvailableClass,
    bookedOutHidden,
    eventWrapperBackGroundClass,
    timeWrapperBgClass,
    slotsAvailableText,
  } = prepareStyling(event);

  return (
    <div
      key={event.id}
      className={
        "mb-[3px] flex h-[75px] rounded-[10px] " + eventWrapperBackGroundClass
      }
    >
      <div
        className={
          "flex h-full w-[7%] flex-col items-center justify-between rounded-[10px] py-3 text-[20px] font-medium " +
          timeWrapperBgClass
        }
      >
        <span>{event.start.toHumanReadableTimeString()}</span>

        <span className="text-black/50">
          {event.end.toHumanReadableTimeString()}
        </span>
      </div>
      <div className="ml-4 flex flex-col justify-between py-3">
        <span className="inline-block text-[22px] font-medium">
          {event.title}
        </span>
        <div className="inline-block text-[18px] font-normal text-black/70">
          <span>{`${event.descriptionShort}  ·  ${event.location}`}</span>
          <span
            className={"font-medium " + slotsAvailableClass}
          >{`  ·  ${slotsAvailableText}`}</span>
        </div>
      </div>
      <div className="ml-auto flex items-center text-[22px] font-medium ">
        <span className={"mr-20 " + bookedOutHidden}>Ausgebucht</span>
        <EventListItemButtons
          eventId={event.id}
          onDeleteEvent={onDeleteEvent}
          onDuplicateEvent={onDuplicateEvent}
        />
      </div>
    </div>
  );
};

function prepareStyling(event: Event) {
  let slotsAvailableClass = " ";
  let bookedOutHidden = "hidden";
  let eventWrapperBackGroundClass = "bg-primary";
  let timeWrapperBgClass = "bg-primaryDark";

  const slotsAvailableText =
    event.slotsAvailable === 1
      ? `${event.slotsAvailable} Platz frei`
      : `${event.slotsAvailable} Plätze frei`;

  if (event.slotsAvailable === 0) {
    slotsAvailableClass = "hidden";
    bookedOutHidden = " ";
    eventWrapperBackGroundClass = "bg-grey";
    timeWrapperBgClass = "bg-greyDark";
  } else if (event.slotsAvailable <= 3) slotsAvailableClass = "text-red/70";

  return {
    slotsAvailableClass,
    bookedOutHidden,
    eventWrapperBackGroundClass,
    timeWrapperBgClass,
    slotsAvailableText,
  };
}

export default EventListItem;
