import { ThreeDots } from "react-loader-spinner";

interface LoadingIndicatorProps {
  show: boolean;
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  return props.show ? (
    <div className="absolute z-50 flex h-full w-full items-center justify-center">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#70CAD1"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  ) : (
    <></>
  );
};

export default LoadingIndicator;
