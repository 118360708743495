import { NavLink } from "react-router-dom";
import Navigation from "./Navigation";
import NavigateBack from "./NavigateBack";
import { useSessionContext } from "../context/SessionContext";

interface EventHeaderProps {
  label?: string;
  to?: string | number;
  showNavigateBack?: boolean;
}

const EventHeader = ({
  label = "Alle Veranstaltungen",
  to = "/",
  showNavigateBack = true,
}: EventHeaderProps) => {
  const [session] = useSessionContext();

  return (
    <>
      <div className="flex h-[50px] items-center gap-1 rounded-[10px] bg-primary text-[20px]">
        <Navigation />

        <div className="flex h-full items-center rounded-[10px] bg-primaryDark px-[30px] font-medium ">
          Veranstaltungen
        </div>

        <NavLink
          className={
            "text-black/35 mr-5 ml-auto text-[16px] font-light hover:text-white"
          }
          to={"/login"}
        >
          {session.user ? session.user.firstname : "Login"}
        </NavLink>
        <span className="text-black/35 mr-5 ml-auto text-[16px] font-light">
          powered by TicketFlamingo
        </span>
      </div>
      {showNavigateBack && <NavigateBack label={label} to={to} />}
    </>
  );
};

export default EventHeader;
