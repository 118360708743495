import EventHeader from "../common/EventHeader";

const NotFoundPage = () => {
  return (
    <div>
      <EventHeader />
      <h1 className="m-5 text-[28px]">Oops something went wrong</h1>
    </div>
  );
};

export default NotFoundPage;
