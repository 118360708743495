import {
  Event,
  EventWithoutIdImplementation,
} from "@code-on-the-rocks/ticket-flamingo-common";
import { DateTime } from "@code-on-the-rocks/ts-datetime";
import _ from "lodash";

export function datetimeLocal(datetime: Date) {
  const dt = new Date(datetime);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt.toISOString().slice(0, 16);
}

export const generatUUID = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};

export function getHourFromDate(milliseconds: number) {
  return ("0" + new Date(milliseconds).getHours()).substr(-2);
}

export function getMinutesFromDate(milliseconds: number) {
  return ("0" + new Date(milliseconds).getMinutes()).substr(-2);
}

export function verifyEnvironment(envVars: string[]) {
  const missingVars: string[] = [];

  envVars.forEach((v) => {
    if (!process.env[v]) {
      missingVars.push(v);
    }
  });

  if (missingVars.length > 0) {
    throw new Error("Environment Variables missing: " + missingVars.join(", "));
  }
}

// TODO: move to own module

export function createEmptyEventWithoutId() {
  return new EventWithoutIdImplementation(
    "",
    "",
    "",
    "",
    new DateTime(0),
    new DateTime(0),
    0,
    "",
    []
  );
}

export function tranformToEventWithoutId(event: Event) {
  return new EventWithoutIdImplementation(
    event.title,
    event.descriptionShort,
    event.descriptionLong,
    event.location,
    new DateTime(event.start.milliseconds),
    new DateTime(event.end.milliseconds),
    event.slotsAvailable,
    event.image,
    _.cloneDeep(event.options)
  );
}
