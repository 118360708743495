import { Route } from "react-router-dom";
import { Routes } from "react-router";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/common/ProtectedRoute";
import { useSessionContext } from "./components/context/SessionContext";
import EventListPage from "./components/pages/EventListPage";
import EventCustomerPage from "./components/pages/EventCustomerPage";
import EventEditPage from "./components/pages/EventEditPage";
import CheckOutPage from "./components/pages/CheckOutPage";
import CheckOutContext from "./components/context/CheckOutContext";
import NotFoundPage from "./components/pages/NotFoundPage";
import LoginPage from "./components/pages/LoginPage";

function App() {
  const defaultProtectedRouteProps = useSessionLogic();

  return (
    //TODO: remove outer diff, only exists for testing
    <div className="mx-60 my-5 flex flex-col gap-20">
      <CheckOutContext>
        <Routes>
          <Route path="/" element={<EventListPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/checkout/" element={<CheckOutPage />} />
          <Route path="/events/:id" element={<EventCustomerPage />} />
          <Route
            path="/events/new"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<EventEditPage />}
              />
            }
          />
          <Route
            path="/events/new-from-template"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<EventEditPage />}
              />
            }
          />
          <Route
            path="/events/new-template"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<EventEditPage />}
              />
            }
          />
          <Route
            path="/events/edit/:id"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<EventEditPage />}
              />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </CheckOutContext>
    </div>
  );
}

function useSessionLogic() {
  const [sessionContext, updateSessionContext] = useSessionContext();

  const setRedirectPath = (path: string) => {
    updateSessionContext({ ...sessionContext, redirectPath: path });
  };

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    user: sessionContext.user,
    authenticationPath: "/login",
    redirectPath: sessionContext.redirectPath,
    setRedirectPath: setRedirectPath,
  };

  return defaultProtectedRouteProps;
}

export default App;
