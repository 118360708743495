import React from "react";
import { useRef } from "react";
import { saveImage } from "../../api/imageApi";
import LoadingIndicator from "./LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

interface ImageUploaderProps {
  onImageChange: (url: string) => void;
  imageUploading: boolean;
  setImageUploading: (imageUploading: boolean) => void;
  previewUrl: string;
  setPreviewUrl: (previewUrl: string) => void;
}

const validFileTypes = ["image/png", "image/webp", "image/jpeg"];

const ImageUploader = (props: ImageUploaderProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const imageOpacity = props.imageUploading ? "opacity-50" : "";

  return (
    <div className="w-[fit-content] min-w-[508px]">
      <label>Titelbild</label>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={(e) =>
          handleDrop(
            e,
            props.setPreviewUrl,
            props.onImageChange,
            props.setImageUploading
          )
        }
        className="mt-1 flex min-h-[250px] w-full cursor-pointer rounded-[10px] border-2 border-primaryDark bg-primary/20 p-5 focus:outline-primaryDark"
        onClick={() => fileInput.current!.click()}
      >
        {!props.previewUrl && (
          <p className="m-auto text-center">
            Click to select <br /> or <br /> Drag and drop image
          </p>
        )}
        <input
          type="file"
          accept={validFileTypes.join(", ")}
          ref={fileInput}
          hidden
          onChange={(e) =>
            uploadImage(
              e.target.files![0],
              props.setPreviewUrl,
              props.onImageChange,
              props.setImageUploading
            )
          }
        />
        {props.previewUrl && (
          <div>
            <div className="relative">
              <LoadingIndicator show={props.imageUploading} />
              {/* use same height-widht-ration as in event details, to provide user the correct preview */}
              <img
                className={
                  "h-[250px] w-[465px] rounded-[10px] object-cover " +
                  imageOpacity
                }
                src={props.previewUrl}
                onLoad={() => URL.revokeObjectURL(props.previewUrl)}
                alt="event feature"
              />
            </div>
            {/* {showFileName && <span className="text-sm"> {image!.name} </span>} */}
            <div className="mt-2 flex justify-center opacity-90">
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="2x"
                className=" text-red/80 hover:text-red"
                onClick={(e) =>
                  handleRemoveImage(e, props.setPreviewUrl, props.onImageChange)
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const uploadImage = async (
  file: File,
  setPreviewUrl: (url: string) => void,
  onImageChange: (url: string) => void,
  setImageUploading: (uploading: boolean) => void
) => {
  if (file && validFileTypes.includes(file.type)) {
    setImageUploading(true);
    setPreviewUrl(URL.createObjectURL(file));

    const imageUrl = await saveImage(file);
    setImageUploading(false);
    onImageChange(imageUrl);
  }
};

const handleDragOver = (event: React.DragEvent) => {
  if (
    event.dataTransfer &&
    validFileTypes.includes(event.dataTransfer.items[0].type)
  ) {
    event.preventDefault();
    event.currentTarget.classList.remove("bg-primary/20");
    event.currentTarget.classList.add("bg-primary");
  }
};

const handleDragLeave = (event: React.DragEvent) => {
  event.preventDefault();
  event.currentTarget.classList.add("bg-primary/20");
  event.currentTarget.classList.remove("bg-primary");
};

const handleDrop = (
  event: React.DragEvent,
  setPreviewUrl: (url: string) => void,
  onImageChange: (url: string) => void,
  setImageUploading: (uploading: boolean) => void
) => {
  event.preventDefault();
  event.stopPropagation();

  event.currentTarget.classList.remove("bg-primary");
  event.currentTarget.classList.add("bg-primary/20");

  uploadImage(
    event.dataTransfer.files[0],
    setPreviewUrl,
    onImageChange,
    setImageUploading
  );
};

const handleRemoveImage = (
  event: React.MouseEvent,
  setPreviewUrl: (url: string) => void,
  onImageChange: (url: string) => void
) => {
  event.preventDefault();
  event.stopPropagation();
  setPreviewUrl("");
  onImageChange("");
};

export default ImageUploader;
