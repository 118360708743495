import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserWithoutPassword } from "../../user_temporary";

export type ProtectedRouteProps = {
  user: UserWithoutPassword | null;
  authenticationPath: string;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  user,
  authenticationPath,
  redirectPath,
  setRedirectPath,
  outlet,
}: ProtectedRouteProps) {
  const currentLocation = useLocation();

  useEffect(() => {
    if (!user) {
      setRedirectPath(currentLocation.pathname);
    }
  }, [user, setRedirectPath, currentLocation]);

  if (user && (!redirectPath || redirectPath === currentLocation.pathname)) {
    return outlet;
  } else {
    return (
      <Navigate
        replace
        to={{ pathname: user ? redirectPath : authenticationPath }}
      />
    );
  }
}
