import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Event } from "@code-on-the-rocks/ticket-flamingo-common/dist/Event";
import EventHeader from "../common/EventHeader";
import TicketSection from "../event-ticket/TicketSection";
import { TicketBuyIntent } from "../event-ticket/TicketItem";
import CustomerInfoForm, {
  CustomerInfo,
} from "../event-ticket/CustomerInfoForm";
import { checkOutContext } from "../context/CheckOutContext";
import { getEvent } from "../../api/eventApi";
import EventNotFound from "../event/EventNotFound";

const EventCustomerPage = () => {
  const [
    event,
    ticketBuyIntents,
    hideFeatureImage,
    setHideFeatureImage,
    hideCustomerInfo,
    setHideCustomerInfo,
    handleChangeTicketAmount,
    handleSubmitToCheckout,
  ] = useEventCustomerPageLogic();

  return (
    <div>
      <EventHeader />

      {event && (
        <>
          <h1 className="text-[36px] font-light">{event.title}</h1>
          <div className="mt-2 flex flex-col text-[20px] font-normal text-black/70">
            <span>{`${new Date(
              event.start.milliseconds
            ).toLocaleDateString()} @ ${event.start.toHumanReadableTimeString()} - ${event.end.toHumanReadableTimeString()}`}</span>
            <span>{event.location}</span>
          </div>
          <img
            src={event.image}
            onLoad={() => setHideFeatureImage(false)}
            alt="feature"
            className="mt-4 h-[390px] w-[726px] rounded-[10px] object-cover"
            hidden={hideFeatureImage}
          />
          {ticketBuyIntents && (
            <div className="mt-6">
              <TicketSection
                creationMode={false}
                headline="Tickets"
                tickets={ticketBuyIntents}
                slotsAvailable={event.slotsAvailable}
                amountChangeable={true}
                onTicketAmountChange={handleChangeTicketAmount}
              >
                <button
                  className="btn-primary ml-auto"
                  onClick={() => setHideCustomerInfo(!hideCustomerInfo)}
                  disabled={
                    ticketBuyIntents.filter((ticket) => ticket.amount > 0)
                      .length === 0
                  }
                >
                  Jetzt buchen
                </button>

                <CustomerInfoForm
                  hidden={hideCustomerInfo}
                  onFormSubmit={handleSubmitToCheckout}
                />
              </TicketSection>
            </div>
          )}
          <div className="mt-5">
            <h3 className="text-[22px] text-primaryDark">Beschreibung</h3>
            <p className="mt-2 text-[20px]">{event.descriptionLong}</p>
            <h3 className="mt-5 text-[22px] text-primaryDark">
              Du hast noch Fragen?
            </h3>
            <p className="mt-2 text-[20px]">Dann melde dich gerne bei mir</p>
            <button className="btn-primary">Kontakt</button>
          </div>
        </>
      )}
      {!event && <EventNotFound />}
    </div>
  );
};

function useEventCustomerPageLogic(): [
  Event | undefined,
  TicketBuyIntent[],
  boolean,
  (hide: boolean) => void,
  boolean,
  (hide: boolean) => void,
  (ticketId: string, step: number) => void,
  (customerInfo: CustomerInfo) => void
] {
  const navigate = useNavigate();
  const checkOutData = useContext(checkOutContext);
  const { id } = useParams();
  const [event, setEvent] = useState<Event>();
  const [ticketBuyIntents, setTicketBuyIntents] = useState<TicketBuyIntent[]>(
    []
  );
  const [hideFeatureImage, setHideFeatureImage] = useState(true);
  const [hideCustomerInfo, setHideCustomerInfo] = useState(true);

  if (!id) {
    navigate("/not-found");
  }

  useEffect(() => {
    async function fetchData() {
      const event = await getEvent(id!);
      if (event) {
        setEvent(event);

        setTicketBuyIntents(
          event.options.map((ticket) => {
            return { ...ticket, amount: 0 };
          })
        );
      }
    }
    fetchData();
  }, [id]);

  const handleChangeTicketAmount = (ticketId: string, step: number) => {
    if (event && ticketBuyIntents) {
      const newTicketAmount = ticketBuyIntents.map((ticket) => ({ ...ticket }));
      const index = newTicketAmount.findIndex(
        (ticked) => ticked.id === ticketId
      );
      const newValue = newTicketAmount[index].amount + step;

      newTicketAmount[index].amount = newValue;

      if (newTicketAmount.filter((ticket) => ticket.amount > 0).length === 0) {
        setHideCustomerInfo(true);
      }

      if (
        newTicketAmount.reduce((sum, ticket) => sum + ticket.amount, 0) >
        event.slotsAvailable
      )
        return;

      if (newValue < 0) return;

      setTicketBuyIntents(newTicketAmount);
    }
  };

  const handleSubmitToCheckout = (customerInfo: CustomerInfo) => {
    if (event && ticketBuyIntents) {
      checkOutData!.setCheckOutData({
        title: event.title,
        tickets: ticketBuyIntents.filter((ticket) => ticket.amount > 0),
        customerInfo: customerInfo,
      });

      navigate("/checkout");
    }
  };

  return [
    event,
    ticketBuyIntents,
    hideFeatureImage,
    setHideFeatureImage,
    hideCustomerInfo,
    setHideCustomerInfo,
    handleChangeTicketAmount,
    handleSubmitToCheckout,
  ];
}

export default EventCustomerPage;
